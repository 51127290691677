import React, { useEffect } from 'react';
import Layout from '../templates/Layout';
import { navigate } from 'gatsby-plugin-intl';

const NotFoundPage = () => {
  // typeof window !== undefined && navigate('/blog');
  return (
    <Layout>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  );
};

export default NotFoundPage;
